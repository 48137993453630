<template>
  <div>
 
  <MovimientoProducto :id_puesto="store.state.puestoSelect.id_puesto" />
</div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import MovimientoProducto from '@/views/sistema/inventario/reporte/movimiento-producto/MovimientoProducto.vue'
import store from '@/store'
// demos

export default {
  components: { MovimientoProducto },
  setup() {
    return {store}
  },
}
</script>
